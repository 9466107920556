/**
 * 日常点検ページ用のスクリプト
 */
if (is_route('daily_check.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        daily_check_event_set();
        daily_check_setting_event_set();
    });
}

/**
 * ページ読み込み時のイベント設定
 */
window.daily_check_event_set = function () {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function (e) {
        _modal.create(this, 'setting-modal', ['id', 'date', 'user_id', 'vehicle_id', 'section_id'], true);
    });

    /*---- 詳細表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-detail-modal]', function (e) {
        _modal.create(this, 'detail-modal', ['id', 'date', 'user_id', 'vehicle_id']);
    });

    /*---- 保存時 ----*/

    // 保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
        });
    });

    /*---- 印刷時 ----*/

    $(document).on('click', '[data-print]', function (e) {
        // バブリング停止
        e.stopPropagation();
        let action = $(this).data('print');
        // データを追加
        let datas = {'id': $(this).data('daily_check_id')};
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

}
/**
 * 日常点検設定モーダル表示時のイベント設定
 */
window.daily_check_setting_event_set = function ()
{
    // 点検項目一覧セット
    $(document).on('change', 'select[name="section_id"]' , function() {
        $('#daily-check-item-list').html('');
        $('#no-check-items').show();
        if (undefined == $(this).val() || $(this).val().length === 0) return;
        let url = $(this).data('check_items');
        let data = {'section_id': $(this).val(), 'id':$('#daily_check-setting-modal-id').val()};
        _ajax.post(url, data, function(results) {
            if (results.length > 0) {
                $('#daily-check-item-list').html(results);
                $('#no-check-items').hide();
            }
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        }, 'html');
    });
}
/**
 * 日常点検選択モーダル表示時のイベント設定
 * @param {*} selectedCallBack 選択ボタン押下時のコールバック関数
 */
window.daily_check_select_event_set = function (selectedCallBack) {

    let modalId = '#daily_check-select-modal';
    
    // 選択ボタン押下時
    $(document).on('click', '.daily_check-select-table .btn-select', function (e) {
        // 呼び出し元画面に戻り値を設定する
        selectedCallBack($(this).data('id'));
        // モーダルを閉じる
        _modal.hide(modalId);
    });

}
