/**
 * グループ会社ページ用のスクリプト
 */
if (is_route('contract.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        contract_event_set();
        contract_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.contract_event_set = function() {

    /*---- 設定の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'setting-modal', ['id'], false, function() {
            contract_option_set_form();
        });
    });

    /*---- 設定の保存確認時 ----*/

    // 設定の保存確認時
    $(document).on('click', '[data-save-confirm-modal]', function () {
        const modalId = '#contract-save-modal'
        const action = $(this).data('action');
        const datas = $('#contract-setting-modal-form').serialize();
        _ajax.post(action, datas, function (results) {
            $(modalId + ' .modal-content').html(results);
            _modal.show(modalId);
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.set('#contract-setting-modal', xhr, status, errorThrown);
        }, 'html');
    });

    /*---- 設定の保存時 ----*/

    // 設定実行時
    $(document).on('click', '[data-save-modal]', function () {
        // 有償機能(スマホ)内のチェックが外れている場合は契約数をリセット
        $('.contract-option-list [name="smartphone-option"]').each(function(){
            if(!$(this).prop('checked')) {
                $(this).parents('.contract-option-item').find('.contract-option-detail input').val('');
            }
        })
        _modal.submit(this, 'save-modal', function(results) {
            contract_certification_modal(results);
        });
    });

    /*---- ライセンスキーの再発行確認 ----*/

    // 再発行確認時
    $(document).on('click', '[data-license-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'license-modal', ['id']);
    });

    /*---- ライセンスキーの再発行 ----*/

    // 再発行時
    $(document).on('click', '[data-refresh-modal]', function () {
        _modal.submit(this, 'refresh-modal', function(results) {
            contract_certification_modal(results);
        });
    });

    /*---- 削除確認の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-confirm-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });
}
/**
 * 設定用script
 */
window.contract_setting_event_set = function() {

    // 自動生成実行
    $(document).on('click', '#generate-contract_code', function () {
        let action = $(this).data('action');
        // codeセット
        _ajax.post(action, [], function (results) {
            $('#contract-setting-modal-contract_code').val(results.generated);
        }, function(xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- オプションのチェックボックスクリック時 ----*/

    $(document).on('change', 'input.group-option', function() {
        $(this).parents('.contract-option-item').find('.contract-option-detail').slideToggle(100);
        contract_option_switch($(this));
    });

    /*---- 選択されている会社の変更時 ----*/

    $(document).on('change', '#contract-setting-modal-company_id', function () {
        contract_option_set_form();
    });
    
    /*---- 有償プラン選択時 ----*/

    $(document).on('change', '#contract-setting-modal-paid_plan_type', function () {
        let visibility = !($('#contract-setting-modal-paid_plan_type').val().length > 0);
        $('#contract_count_container').toggleClass('hidden', visibility);
    });
}

/**
 * オプションフォームを読込
 */
window.contract_option_set_form = function() {
    // オプションフォームを初期化
    $('#contract-option-setting-form').empty();
    $('#contract-local-option-setting-form').empty();
    if ($('#contract-setting-modal-company_id').val().length > 0) {
        const action = $('#contract-option-setting-form').data('action');
        const datas = {
            'company_id': $('#contract-setting-modal-company_id').val(),
            'id': $('#contract-setting-modal-id').val(),
        };
        _ajax.post(action, datas, function(results) {
            const json_results = JSON.parse(results);
            $('#contract-local-option-setting-form').html(json_results.local_option_form);
            $('#contract-option-setting-form').html(json_results.option_form);
            $('input.group-option').each(function() {
                contract_option_switch($(this));
            })
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        }, 'html');
    }    
}

/**
 * ライセンスキーモーダルを表示する
 * @param {*} results
 */
window.contract_certification_modal = function(results) {
    if (results.license_key != null) {
        $('#contract-certification-modal-company_name').html(results.contract.company_name);
        $('#contract-certification-modal-download_url').attr('href', results.download_url);
        $('#contract-certification-modal-license_key').html(results.license_key);
        _modal.show('#contract-certification-modal');
    }
}

/**
 * 各有償機能のチェックボックスON/OFFに伴う付属項目の切り替え
 */
window.contract_option_switch = function(element) {
    var element = $(element);
    var targetElement = element.parents('.contract-option-item').find('.contract-option-detail');
    if (element.prop('checked')) {
        targetElement.find('.options-hidden').prop('disabled', false);
    } else {
        targetElement.find('.group-option-section').prop('checked', false);
        targetElement.find('.options-hidden').prop('disabled', true);
    }
}
