/**
 * 指導記録ページ用のスクリプト
 */
if (is_route('guidance.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        guidance_event_set();
        guidance_setting_event_set();
    });
}

/**
 * 参加者選択制御
 */
window.participant = {
    // 候補者クリア
    clearBefore: function() {
        $('select.before-join').empty();
    },
    // 参加者リセット
    clearAfter: function() {
        let after = $('select.after-join');
        let participantsHidden = $('#participantsHidden');
        let base = after.data('base');
        let type = after.data('guidance_type');
        let kind = after.data('guidance_kind_id');
        let options = '';
        let hidden = '';

        after.empty();
        participantsHidden.empty();

        // 元の状態選択の時は、元に戻す
        if (base.length > 0
            && parseInt($('input[name="guidance_type"]:checked').val()) === parseInt(type)
            && parseInt($('select[name="guidance_kind_id"]').val()) === parseInt(kind)) {
            $.each(base, function(idx, option) {
                options += '<option value=' + option.id + '>' + option.text + '</option>';
                hidden += '<input type="hidden" name="participants[]" value="' + option.id + '" id="participants_' + option.id + '" />';
            });
            after.append(options);
            participantsHidden.append(hidden);
        }

    },
    // 候補者(乗務員)セット
    setBefore: function() {
        let select = $('select.before-join');
        let action = $(select).data('action');
        let data = {
            'guidance_id': $('input[name="id"]').val(),
            'section_id': $('select.section-select').find('option:selected').val(),
            'executed_at': $('input[name="executed_at"]').val(),
            'guidance_type': $('input[name="guidance_type"]:checked').val(),
            'guidance_kind_id': $('select[name="guidance_kind_id"]').val(),
            'type': 'driver'
        }
        _ajax.post(action, data, function (results) {
            participant.clearBefore();
            let options = '';
            $.each(results.options, function(idx, option) {
                options += '<option value=' + option.id + '>' + option.text + '</option>';
            });

            select.append(options);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    },
    // 参加する押下
    setJoin: function() {
        let options = '';
        let hidden = '';
        $('select.before-join').find('option:selected').each(function(){
            options += '<option value=' + $(this).val() + '>' + $(this).text() + '</option>';
            hidden += '<input type="hidden" name="participants[]" value="' + $(this).val() + '" id="participants_' + $(this).val() + '" />';
            $(this).remove();
        });
        $('select.after-join').append(options);
        $('#participantsHidden').append(hidden);
    },
    // 参加しない押下
    setNotJoin: function() {
        let options = '';
        $('select.after-join').find('option:selected').each(function(){
            options += '<option value=' + $(this).val() + '>' + $(this).text() + '</option>';
            $('#participants_' + $(this).val()).remove();
            $(this).remove();
        });
        $('select.before-join').append(options);
    },
    // 参加者＆指導名Disable
    setDisable: function() {
        $('select[name="guidance_kind_id"]').prop('disabled', true);
        $('select.before-join').prop('disabled', true);
        $('select.after-join').prop('disabled', true);
        $('button#add').prop('disabled', true);
        $('button#remove').prop('disabled', true);
    },
    // 参加者＆指導名Enable
    setEnable: function() {
        $('select[name="guidance_kind_id"]').prop('disabled', false);
        $('select.before-join').prop('disabled', false);
        $('select.after-join').prop('disabled', false);
        $('button#add').prop('disabled', false);
        $('button#remove').prop('disabled', false);
    }
}

/**
 * ページ読み込み時のイベント設定
 */
window.guidance_event_set = function () {

    /*---- ページタブ切り替え時 ----*/

    // タブ切り替え時
    $(document).on('click', '[data-page-type]', function() {
        $('#search-form-type').val($(this).data('page-type')).trigger('change');
    });

    /*---- 指導記録詳細表示時 ----*/

    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['date'], true);
    });

    /*---- 参会者一覧表示時 ----*/

    $(document).on('click', '[data-participants-modal]', function () {
        _modal.create(this, 'participants-modal', ['id'], true);
    });

    /*---- 設定表示時 ----*/

    $(document).on('click', '[data-setting-modal]', function (e) {
        // trイベント有効の時
        if (false === $(e.target).hasClass("not-setting-event")) {
            _modal.create(this, 'setting-modal', ['id', 'date'], true,function() {
                /*---- 初期表示 ----*/
                if ($('select.section-select').find('option:selected').length === 0
                    || $('select.section-select').find('option:selected').val() === ''
                    || $('input[name="guidance_type"]:checked').length === 0) {
                    participant.setDisable();
                } else {
                    participant.setEnable();
                    participant.setBefore();
                }
            });
        }
    });

    /*---- 設定時 ----*/

    $(document).on('click', '[data-save-modal]', function () {
        var date = $('#guidance-setting-modal-date').val();
        // 全要素をPOSTするためdisabled解除
        _modal.submit(this, 'save-modal', function(results) {
            // detailモーダルから変更した時
            if (date != null && date.length > 0) {
                if (results.guidance.executed_at) {
                    $('[data-detail-modal=guidance-detail-modal][data-date=' + moment(results.guidance.executed_at).format("YYYY-MM-DD") + ']').trigger('click');
                } else {
                    $('[data-detail-modal=guidance-detail-modal][data-date=' + date + ']').trigger('click');
                }
            }
        });
    });

    /*---- 削除確認時 ----*/

    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    $(document).on('click', '[data-delete-modal]', function () {
        var date = $('#guidance-setting-modal-date').val();
        _modal.submit(this, 'delete-modal', function(results) {
            // detailモーダルから変更した時のみ
            if (date != null && date.length > 0) {
                $('[data-detail-modal=guidance-detail-modal][data-date=' + date + ']').trigger('click');
            }
        });
    });

    /*---- 一括印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-print]', function () {
        // 送信値を配列化
        let datas = [];
        let action = extend_url_query($(this).data('print'));
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
}
/**
 * 指導記録設定モーダル表示時のイベント設定
 */
window.guidance_setting_event_set = function () {

    /*---- 営業所切替による指導名と参加者選択出し分け ----*/

    $(document).on('change', '#guidance-setting-modal-form select.section-select', function () {
        // 参加者クリア
        participant.clearAfter();
        // 候補者クリア
        participant.clearBefore();
        if ($(this).find('option:selected').length > 0
            && $(this).find('option:selected').val() !== ''
            && $('input[name="guidance_type"]:checked').length > 0) {
            // 候補者取得
            participant.setEnable();
            participant.setBefore();
        } else {
            participant.setDisable();
        }
    });

    /*---- 指導種別切替による指導名と参加者選択出し分け ----*/

    $(document).on('change', 'input[name="guidance_type"]', function () {
        // 候補者&指導名取得
        $('select.section-select').trigger('change');
    });

    /*---- 指導切替・実施日指定による参加者選択出し分け ----*/

    $(document).on('change', 'select[name="guidance_kind_id"], input[name="executed_at"]', function () {
        if ($('input[name="guidance_type"]:checked').length > 0 && $('select.section-select option:selected').val() !== '') {
            participant.clearAfter();
            // 候補者取得
            participant.setBefore();
        }
    });

    /*---- 参加者追加 ----*/

    $(document).on('click', 'button#add', function () {
        participant.setJoin();
    });

    /*---- 参加者追加 ----*/

    $(document).on('click', 'button#remove', function () {
        participant.setNotJoin();
    });

}
