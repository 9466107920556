/**
 * 事故記録ページ用のスクリプト
 */
if (is_route('accident.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        accident_event_set();
        accident_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.accident_event_set = function () {

    /*---- ページタブ切り替え時 ----*/

    // タブ切り替え時
    $(document).on('click', '[data-page-type]', function() {
        $('#search-form-type').val($(this).data('page-type'));
        $('#search-form-type').trigger('change');
    });

    /*---- 事故記録詳細表示時 ----*/

    // 事故記録詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['date'], true);
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function (e) {
        if ($(e.target).hasClass('file-download')) {
            return;
        }
        _modal.create(this, 'setting-modal', ['id', 'date', 'holding_id', 'company_id', 'section_id', 'user_id', 'vehicle_id'],true);
    });

    /*---- 設定時 ----*/

    // 事故記録設定時
    $(document).on('click', '[data-save-modal]', function () {
        var date = $('#accident-setting-modal-date').val();
        _modal.submit(this, 'save-modal', function(results) {
            // detailモーダルから変更した時
            if (date != null && date.length > 0) {
                if (results.accident.occurred_at) {
                    $('[data-detail-modal=accident-detail-modal][data-date=' + moment(results.accident.occurred_at).format("YYYY-MM-DD") + ']').trigger('click');
                } else {
                    $('[data-detail-modal=accident-detail-modal][data-date=' + date + ']').trigger('click');
                }
            }
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        var date = $('#accident-setting-modal-date').val();
        _modal.submit(this, 'delete-modal', function(results) {
            // detailモーダルから変更した時のみ
            if (date != null && date.length > 0) {
                $('[data-detail-modal=accident-detail-modal][data-date=' + date + ']').trigger('click');
            }
        });
    });

    /*---- 一括印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-print]', function () {
        // 送信値を配列化
        let datas = [];
        let action = extend_url_query($(this).data('print'));
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 事故記録印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-report-print]', function (e) {
        // バブリング停止
        e.stopPropagation();
        let action = $(this).data('report-print');
        let datas = [];
        // データを追加
        datas.push({'name': 'id', 'value': $(this).data('id')});
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
    
    /*---- カレンダーの日付モーダルでの印刷ボタン押下時 ----*/

    // 印刷時
    $(document).on('click', '[data-day-print]', function (e) {
        // バブリング停止
        e.stopPropagation();
        let action = extend_url_query($(this).data('day-print'));
        let datas = [];
        // データを追加
        datas.push({'name': 'date', 'value': $(this).data('date')});
        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
    
    /*---- 連続無事故初期起算日の設定 ----*/

    // 連続無事故初期起算日の設定表示時
    $(document).on('click', '[data-config-modal]', function () {
        _modal.create(this, 'config-modal', ['id', 'company_id'], true);
    });
    
    // 連続無事故初期起算日の設定保存時
    $(document).on('click', '[data-config-save-modal]', function () {
        _modal.submit(this, 'config-save-modal');
    });

}
/**
 * 詳細モーダル表示時のイベント設定
 * @uses ダッシュボード・社員台帳・車両台帳からモーダル表示する際にも呼び出される
 */
window.accident_setting_event_set = function () {

    // 事故記録タイプ変更
    $(document).on('change', 'select[name="accident_type"]', function () {
        // 乗務後事故記録の場合
        if ($(this).val() === '2') {
            $('#accident-setting-modal-count_of_death').prop('disabled', false);
            $('#accident-setting-modal-count_of_serious').prop('disabled', false);
            $('#accident-setting-modal-count_of_minor').prop('disabled', false);
        } else {
            $('#accident-setting-modal-count_of_death').val('').prop('disabled', true);
            $('#accident-setting-modal-count_of_serious').val('').prop('disabled', true);
            $('#accident-setting-modal-count_of_minor').val('').prop('disabled', true);
            $('input[name="count_of_death"]').val('');
            $('input[name="count_of_serious"]').val('');
            $('input[name="count_of_minor"]').val('');
        }
    });

}
