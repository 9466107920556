/**
 * 配車板ページ用のスクリプト
 */
import {Draggable} from "fullcalendar-scheduler";

if (is_route('order.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function () {
        order_event_set();
        order_setting_event_set();
    });
}

/**
 * ページ読み込み時のイベント設定
 */
window.order_event_set = function ()
{
    // ------------------------------
    // drawer部制御(未割当リスト)
    // ------------------------------

    let drawer = {

        /*---- properties ----*/
        _popoverEl: '',  // popover要素

        /*---- 初期化 ----*/
        init: function() {
            // 初期表示order指定あればOPEN
            let params = _request.get_parameters(location.search);
            if (params.some((p) => p.name === 'order')) {
                drawer.toggle(true);
            }
            // OPEN
            $(document).on('click', '.order-page #drawer-open', function () {
                console.info('order');
                drawer.toggle($('.order-page #drawer-content').is(':hidden'));
            });
            // CLOSE
            $(document).on('click', '.order-page #drawer-close', function () {
                drawer.toggle($('.order-page #drawer-content').is(':hidden'));
            });
            drawer.popover();
        },

        /*---- 表示制御 ----*/
        toggle: function(open) {
            let content = $('.order-page #drawer-content');
            // escape when not selector
            if (content.length === 0) {
                return;
            }
            let parent = content.parent('.order-page #drawer-toggle');
            let calendarBlock = $('#calendar-block');
            if (open) {
                // open
                $('.order-page #drawer-open').addClass('d-none');
                content.removeClass('d-none');
                parent.removeClass('col-auto').addClass('col-12 col-lg-2 col-md-4 col-sm-5').css({width: "unset"});
                calendarBlock.addClass('col-12 col-lg-10 col-md-8 col-sm-7').removeClass('col-auto').css({width:"unset"});
            } else {
                // close
                $('.order-page #drawer-open').removeClass('d-none');
                content.addClass('d-none');
                parent.addClass('col-auto').removeClass('col-12 col-lg-2 col-md-4 col-sm-5').css({width: "5%"});
                calendarBlock.addClass('col-auto').removeClass('col-12 col-lg-10 col-md-8 col-sm-7').css({width:"95%"});
            }
            drawer.popover();
            timeline._calendar.render();
        },

        /*---- popover制御 ----*/
        popover: function() {
            drawer._popoverEl = $('.external-popover');
            if (drawer._popoverEl.length > 0) {
                drawer._popoverEl.popover('dispose');
                drawer._popoverEl.popover({
                    trigger: 'hover',
                    placement: 'top',
                    container: 'body',
                    html: true
                });
            }
        },
    }

    // ------------------------------
    // fullCalendar(配車板timeline)部制御
    // ------------------------------
    var timeline = {

        /*---- properties ----*/
        _calendarId: 'order-calendar',          // 生成要素
        _calendar: {},                          // カレンダーobject
        _calendarEl: [],                        // カレンダー要素
        _editable: false,                       // 編集可否
        _currentDate: new Date().toISOString(), // カレント日付
        _currentView: 'resourceTimelineDay',    // カレントVIEW
        _external: null,                        // 外部ドラッグイベントobject
        _scrollX: -1,                           // スクロール位置横
        _scrollY: -1,                           // スクロール位置縦
        _initOptions: {                         // calendar Option
            slotMinWidth: 10,
            eventMinWidth: 10,
            editable: false,
            initialView: 'resourceTimelineDay',
            initialDate: new Date().toISOString(),
            views: {
                resourceTimelineThreeDay: {
                    type: 'resourceTimeline',
                    duration: {days: 3},
                    buttonText: '3日',
                    dateIncrement: { days: 1 },
                    slotLabelInterval: {hours: 3},
                    slotMinWidth: 10,
                    eventMinWidth: 5,
                },
                resourceTimelineSevenDay: {
                    type: 'resourceTimeline',
                    duration: {days: 7},
                    buttonText: '週',
                    dateIncrement: { days: 1 },
                    slotLabelInterval: {hours: 6},
                    slotDuration: '2:00:00',
                    slotMinWidth: 10,
                    eventMinWidth: 2,
                },
                resourceTimelineSevenDayAmPm: { // 週AMPM表示設定
                    type: 'resourceTimeline',
                    duration: {days: 7},
                    buttonText: '週',
                    dateIncrement: { days: 1 },
                    slotLabelInterval: {hours: 12},
                    slotDuration: '12:00:00',
                    slotLabelContent: function(arg) {
                        if (arg.level > 0 ) {
                            if (arg.text === '0時') {
                                arg.text = '午前';
                            } else {
                                arg.text = '午後';
                            }
                        }
                        return arg.text;
                    },
                    slotMinWidth: 10,
                    eventMinWidth: 2,
                },
                resourceTimelineMonth: {
                    slotLabelInterval: {hours: 24},
                    slotDuration: '12:00:00',
                    slotMinWidth: 10,
                    eventMinWidth: 2,
                    slotLabelFormat: [
                        { day: 'numeric'},
                        // { hour: 'numeric'}
                    ]
                },
            },
            customButtons: {
                switchWeekDisplayTime: {
                    text: '午前/午後',
                    click: function() {
                        // change view
                        if (timeline._calendar.view.type === 'resourceTimelineSevenDayAmPm') {
                            timeline.weeklyDisplaySetting('resourceTimelineSevenDay');
                        } else if (timeline._calendar.view.type === 'resourceTimelineSevenDay') {
                            timeline.weeklyDisplaySetting('resourceTimelineSevenDayAmPm');
                        }
                    }
                }
            },
            headerToolbar: {
                left: 'prev,today,next',
                center: 'title',
                right: 'switchWeekDisplayTime resourceTimelineDay,resourceTimelineThreeDay,resourceTimelineSevenDay,resourceTimelineMonth',
            },
            nowIndicator: true,
        },

        /*---- カレンダー生成 ----*/
        setCalender: function() {
            // set selector
            timeline._calendarEl = $('#' + timeline._calendarId);
            // escape when not selector
            if (timeline._calendarEl.length === 0) {
                return;
            }
            // init option
            timeline._initOptions.editable = !!timeline._calendarEl.data('is_editable');
            timeline._initOptions.initialView = timeline._currentView;
            timeline._initOptions.initialDate = timeline._currentDate;

            // create fullCalendar object
            timeline._calendar = fullCalendar(timeline._calendarId,
                extend_url_query(timeline._calendarEl.data('resource')),    // resourceURL
                extend_url_query(timeline._calendarEl.data('event')),       // eventURL
                timeline._calendarEl.data('content_header'),                // resourceHeaderText
                timeline._initOptions
            );

            // set option
            timeline.setOption();

            // rendering
            timeline._calendar.render();
        },

        /*---- set option ----*/
        setOption: function() {
            let externalEl = $('#external-draggable');  // 外部ドラッグ対象リスト

            /* setting draggable(リソースをまたいでの制御は、eventデータのconstrainで指定) */
            // 乗務員：同一会社所属、車両：同一営業所所属リソースに対してdraggable=trueとなる
            timeline._calendar.setOption('eventResourceEditable', true);

            /* view */
            // keep scroll position
            timeline._calendar.setOption('scrollTimeReset', false);
            timeline._calendar.setOption('viewDidMount', function (view) {
                // control to display button for switch week time/AMPM
                timeline.displaySwitchForWeekly(view.type);
                // keep scroll position before update(doesn't work)
                if(timeline._scrollX >= 0 || timeline._scrollY >= 0) {
                    setTimeout(function () {
                        document.querySelector('.fc-timeline-header').parentNode.scrollLeft = timeline._scrollX;
                        document.querySelector('.fc-timeline-slots').parentNode.parentNode.scrollTo(timeline._scrollX, timeline._scrollY);
                    }, 300);
                }
            });
            //change view
            timeline._calendar.setOption('datesSet', function (info) {
                timeline.displaySwitchForWeekly(info.view.type);
            });

            /* group by section_id when not alone */
            if (!timeline._calendarEl.data('is_one_section')) {
                // every section_id
                timeline._calendar.setOption('resourceGroupField', 'section_id');
                // edit section label
                timeline._calendar.setOption('resourceGroupLabelContent', function (arg) {
                    let arr = Object.values(timeline._calendar.currentData.resourceStore);
                    let result = arr.filter(function (resource) {
                        return arg.groupValue === resource.extendedProps.section_id;
                    });
                    let groupName = result.length > 0 ? result[0].extendedProps.section_name : '';
                    return {html: `<strong>${groupName}</strong> `};
                });
            }

            /* control event */
            // initial popover
            timeline._calendar.setOption('eventDidMount', function (eventObj) {
                // 静止表示状態で初期化(ドラッグ/リサイズ中でなければpopover対象とする)
                if (!eventObj.isDragging && !eventObj.isResizing) {
                    $(eventObj.el).popover({
                        content: eventObj.event.extendedProps.description,
                        trigger: 'hover',
                        placement: 'auto',
                        container: 'body',
                        html: true
                    });
                }
            });
            // escape when not editable
            if (!timeline._initOptions.editable) {
                return;
            }
            // click event data
            timeline._calendar.setOption('eventClick', function (eventObj) {
                // close popover
                $(eventObj.el).popover('hide');
                // open setting modal
                let data = {
                    id: eventObj.event.id,
                };
                timeline.openModal(eventObj, data);
            });
            // drag start event data
            timeline._calendar.setOption('eventDragStart', function (eventObj) {
                $('div.popover').hide();
                $(eventObj.el).popover('disable');
            });
            // drag stop event data
            timeline._calendar.setOption('eventDragStop', function (eventObj) {
                $('div.popover').hide();
            });
            // drop event data
            timeline._calendar.setOption('eventDrop', function (eventObj) {
                let start = moment(eventObj.event.start);
                let end = moment(eventObj.event.end);
                let data = {
                    id: eventObj.event.id,
                    loading_date: start.format('YYYY-MM-DD'),
                    loading_time: start.format('HH:mm:ss'),
                    unloading_date: end.format('YYYY-MM-DD'),
                    unloading_time: end.format('HH:mm:ss'),
                };
                if (eventObj.event.extendedProps.user_id) {
                    data.user_id = eventObj.event._def.resourceIds[0];
                }
                if (eventObj.event.extendedProps.vehicle_id) {
                    data.vehicle_id = eventObj.event._def.resourceIds[0];
                }
                timeline.openModal(eventObj, data);
            });
            // resize start event data
            timeline._calendar.setOption('eventResizeStart', function (eventObj) {
                $('div.popover').hide();
                $(eventObj.el).popover('disable');
            });
            // resize stop event data
            timeline._calendar.setOption('eventResizeStop', function (eventObj) {
                $(eventObj.el).popover('update');
            });
            // resize event data
            timeline._calendar.setOption('eventResize', function (eventObj) {
                let start = moment(eventObj.event.start);
                let end = moment(eventObj.event.end);
                let data = {
                    id: eventObj.event.id,
                    loading_date: start.format('YYYY-MM-DD'),
                    loading_time: start.format('HH:mm:ss'),
                    unloading_date: end.format('YYYY-MM-DD'),
                    unloading_time: end.format('HH:mm:ss'),
                };
                if (eventObj.event.extendedProps.hasOwnProperty('user_id')) {
                    data.user_id = eventObj.event._def.resourceIds[0];
                }
                if (eventObj.event.extendedProps.hasOwnProperty('vehicle_id')) {
                    data.vehicle_id = eventObj.event._def.resourceIds[0];
                }
                timeline.openModal(eventObj, data);
            });

            /* control drag on external selector */
            if (externalEl.length > 0 && timeline._initOptions.editable) {
                // initialize the external draggable events when not initialize
                if (timeline._external !== null) {
                    timeline._external.destroy();
                }
                timeline._external = new FullCalendar.Draggable(externalEl.get(0), {
                    itemSelector: '.external-draggable-item',
                    eventData: function (eventEl) {
                        let resourceId = 'vehicle_id';
                        if ($('input[name="allocate_type"]:checked').val() === '2') {
                            resourceId = 'user_id';
                        }
                        // set external selector to event object
                        return {
                            title: eventEl.innerText,
                            id: $(eventEl).data('id'),
                            constraint: {
                                resourceIds: $(eventEl).data('constraint').split(','),
                            },
                            extendedProps: {
                                description: $(eventEl).data('content'),
                                setting: $(eventEl).data('action'),
                                loading_date: $(eventEl).data('loading_date'),
                                loading_time: $(eventEl).data('loading_time'),
                                unloading_date: $(eventEl).data('unloading_date'),
                                unloading_time: $(eventEl).data('unloading_time'),
                                [resourceId]: '',
                            }
                        };
                    },
                });
                // set external drag on calendar
                timeline._calendar.setOption('droppable', true);
                timeline._calendar.setOption('plugins', ['interactivePlugin']);
                // control when drop
                timeline._calendar.setOption('eventReceive', function (eventObj) {
                    let start = moment(eventObj.event.start);
                    let end = start.clone().add(1, "hours");
                    let data = {
                        id: eventObj.event.id,
                    };
                    if (eventObj.event.extendedProps.loading_date.length === 0) {
                        data.loading_date = start.format('YYYY-MM-DD');
                    }
                    if (eventObj.event.extendedProps.loading_time.length === 0) {
                        data.loading_time = start.format('HH:mm:ss');
                    }
                    if (eventObj.event.extendedProps.unloading_date.length === 0) {
                        data.unloading_date = end.format('YYYY-MM-DD');
                    }
                    if (eventObj.event.extendedProps.unloading_time.length === 0) {
                        data.unloading_time = end.format('HH:mm:ss');
                    }
                    if (eventObj.event.extendedProps.hasOwnProperty('user_id')) {
                        data.user_id = eventObj.event._def.resourceIds[0];
                    }
                    if (eventObj.event.extendedProps.hasOwnProperty('vehicle_id')) {
                        data.vehicle_id = eventObj.event._def.resourceIds[0];
                    }
                    timeline.openModal(eventObj, data);
                });
            }
        },

        /*---- カレント情報更新 ----*/
        currentInfo: function() {
            if (timeline._calendar.hasOwnProperty('isRendered')) {
                timeline._currentDate = timeline._calendar.getDate().toISOString();
                timeline._currentView = timeline._calendar.view.type;
                if ($('.fc-timeline-slots').length > 0) {
                    timeline._scrollX = document.querySelector('.fc-timeline-slots').parentNode.parentNode.scrollLeft;
                    timeline._scrollY = document.querySelector('.fc-timeline-slots').parentNode.parentNode.scrollTop;
                }
            }
        },

        /*---- モーダル表示 ----*/
        openModal: function(eventObj, data) {
            $('div.popover').hide();
            $(eventObj.el).popover('enable');
            let action = extend_url_query(timeline._calendarEl.data('action'));
            let modalId = timeline._calendarEl.data('modal');
            let submit = timeline._calendarEl.data('submit');
            // モーダルのコンテンツを空に
            $('#' + modalId + ' .modal-content').empty();
            // 送信
            _ajax.post(action, data, function (results) {
                $('#' + modalId + ' .modal-content').html(results);
                initialize();
                _modal.show('#' + modalId);
                // 未割当リストの表示状態を保持しておく
                $('[' + submit + ']').data('drawer_open', $('#drawer-content').is(':visible'));
            }, function (xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            }, 'html');
        },

        /*---- 週の時カスタムボタン表示制御 ----*/
        displaySwitchForWeekly: function(type) {
            if (type === 'resourceTimelineSevenDay' || type === 'resourceTimelineSevenDayAmPm') {
                $('.fc-switchWeekDisplayTime-button').removeClass('d-none');
                let setting = timeline._calendarEl.data('weekly_display_type');
                if (setting.length > 0 && setting !== type) {
                    type = setting;
                    timeline._calendar.changeView(type);
                }
                timeline.switchForWeeklyText(type);
            } else {
                $('.fc-switchWeekDisplayTime-button').addClass('d-none');
                $('.fc-resourceTimelineSevenDay-button').removeClass('fc-button-active');
            }
        },
        /*---- 週の時カスタムボタンテキスト切替 ----*/
        switchForWeeklyText: function(type) {
            if (type === 'resourceTimelineSevenDay') {
                $('.fc-switchWeekDisplayTime-button').empty().html('午前/午後').attr('title', '午前/午後');
            } else if (type === 'resourceTimelineSevenDayAmPm') {
                $('.fc-switchWeekDisplayTime-button').empty().html('時間').attr('title', '時間');
                $('.fc-resourceTimelineSevenDay-button').addClass('fc-button-active');
            }
        },
        /*---- 週の時カスタムボタンテキスト切替設定保持 ----*/
        weeklyDisplaySetting: function(type) {
            let action = timeline._calendarEl.data('weekly_display_type_setting_action');
            let key = timeline._calendarEl.data('weekly_display_type_setting_key');
            let params = [
                {'name' : 'setting_key', 'value' : key},
                {'name' : 'setting_value', 'value' : type},
            ];
            // 表示保持用API(user_setting)
            _ajax.post(action, params, function (results) {
                // 完了メッセージをセットする
                // _complete.set(results);
                timeline._calendarEl.data('weekly_display_type', type);
                timeline._calendar.changeView(type);
            }, function (xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            });
        }
    }

    // load
    timeline.setCalender();
    drawer.init();


    /*---- KEYWORD検索制御 ----*/
    // KEYWORD検索フォーム初期化
    if ($("#order-calendar").length === 0) {
        $('.navbar-search-block').removeClass('d-none');
    } else {
        $('#toolbar-keyword-search').val('');
        $('.navbar-search-block').addClass('d-none');
    }

    // load_contents--カレンダー再描画処理のため、共通の検索用イベントをOFF
    $(document).off('change', '#search-form, #toolbar-keyword-search');
    $(document).off('click', '#toolbar-keyword-search-submit, #toolbar-keyword-search-reset');

    // イベント再登録(callbackにsetCalendar処理をCALLする)
    $(document).on('change', '#search-form, #toolbar-keyword-search', function () {
        let open = $('#drawer-content').is(':visible');
        timeline.currentInfo();
        // コンテンツを読込
        load_contents(function () {
            timeline.setCalender();
            drawer.toggle(open);
            setTimeout(function () {
                if ($("#order-calendar").length === 0) {
                    $('.navbar-search-block').removeClass('d-none');
                } else {
                    $('.navbar-search-block').addClass('d-none');
                }
            }, 10);
        }, false);
    });

    $(document).on('click', '#toolbar-keyword-search-submit, #toolbar-keyword-search-reset', function () {
        let open = $('#drawer-content').is(':visible');
        timeline.currentInfo();
        // コンテンツを読込
        load_contents(function () {
            timeline.setCalender();
            drawer.toggle(open);
        }, false);
    });

    /*---- 未割当リスト並び替え ----*/

    $(document).on('click', '#order-by-loading', function () {
        if ($(this).hasClass('already-sort')) {
            return;
        }
        const params = _request.get_parameters(location.search);
        const open = params.some((p) => p.name === 'order');
        if (open) {
            _request.remove_parameters(location.search, ['order']);
        } else {
            _request.set_parameters([{'name': 'order', 'value': 'unloading_at'}])
        }
        timeline.currentInfo();
        load_contents(function () {
            timeline.setCalender();
            drawer.toggle(true);
            setTimeout(function () {
                if ($("#order-calendar").length === 0) {
                    $('.navbar-search-block').removeClass('d-none');
                } else {
                    $('.navbar-search-block').addClass('d-none');
                }
            }, 10);
        }, false);
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function (e) {
        if ($(e.target).hasClass('list-thumb')) {
            return;
        }
        _modal.create(this, 'setting-modal', ['id'], true, function() {
            $('[data-save-modal]').data('drawer_open', $('#drawer-content').is(':visible'));
        });
    });

    /*---- 設定時 ----*/

    // 受注設定時
    $(document).on('click', '[data-save-modal]', function () {
        let open = $(this).data('drawer_open');
        timeline.currentInfo();
        _modal.submit(this, 'save-modal', function () {
            // drawer open
            drawer.toggle(open);
            // カレンダー更新
            timeline.setCalender();
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id'], false, function(){
            $('[data-delete-modal]').data('drawer_open', $('#drawer-content').is(':visible'));
        });
    });

    /*---- 削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        let open = $(this).data('drawer_open');
        timeline.currentInfo();
        _modal.submit(this, 'delete-modal', function () {
            // drawer open
            drawer.toggle(open);
            // カレンダー更新
            timeline.setCalender();
        });
    });

    /*---- 戻る押下 ----*/

    // カレンダー元に戻す
    $(document).on('click', '[data-dismiss="modal"]', function () {
        timeline.currentInfo();
        timeline.setCalender();
    });

    /*---- CSVダウンロード時 ----*/

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let data = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, data);
    });

    /*---- CSVアップロード表示時 ----*/

    // CSVアップロード表示時
    $(document).on('click', '#search-form-action_upload', function () {
        // 初期化処理
        $('#order-csv-setting-modal-csv-submit-message').addClass('display-none').empty();
        $('#order-csv-setting-modal-csv-error').addClass('display-none').empty();
        $('#order-csv-setting-modal-csv').val('');
    });

    /*---- 配車板制御設定表示時 ----*/

    // 制御設定表示時
    $(document).on('click', '[data-config-modal]', function (e) {
        _modal.create(this, 'config-modal', [], true);
    });

    /*---- 制御設定時 ----*/

    // 制御設定時
    $(document).on('click', '[data-config-save-modal]', function () {
        _modal.submit(this, 'config-save-modal');
    });

    /*---- ツールチップ表示項目設定 ----*/

    // 設定
    $(document).on('click', '[data-user-setting-modal]', function (e) {
        _modal.create(this, 'user-setting-modal', ['id'], false);
    });
    // 削除確認
    $(document).on('click', '[data-user-setting-confirm-modal]', function (e) {
        _modal.create(this, 'user-setting-delete-modal', ['id'], false);
    });
    // 削除
    $(document).on('click', '[data-user-setting-delete-modal]', function (e) {
        _modal.submit(this, 'user-setting-delete-modal');
    });

    /*---- CSVアップロード確認時 ----*/

    // CSVアップロード確認時
    $(document).on('change', '#order-csv-setting-modal-csv', function () {
        let modalId = '#order-csv-setting-modal';
        let formId = '#order-csv-setting-modal-form';
        let action = $(formId).data('confirm-action');
        let data = new FormData($(formId).get(0));
        $(modalId + '-csv-error').addClass('display-none');
        $(modalId + '-csv-error').empty();
        $(modalId + '-csv-submit-message').addClass('display-none');
        $(modalId + '-csv-submit-message').empty();
        $("[data-csv-save-modal]").prop("disabled", true);
        // 送信
        _ajax.file(action, data, function (results) {
            $(modalId + '-csv-submit-message').removeClass('display-none');
            $(modalId + '-csv-submit-message').text(results.messages[0]);
            $("[data-csv-save-modal]").prop("disabled", false);
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
            $(this).val('');
            // フォームにエラーメッセージを配置
            $.each(xhr.responseJSON.errors, function (name, message) {
                $(modalId + '-csv-error').removeClass('display-none');
                $(modalId + '-csv-error').append($('<li></li>').text(message));
            });
        });
    });

    /*---- CSVアップロード時 ----*/

    // 設定表示時
    $(document).on('click', '[data-csv-save-modal]', function () {
        let modalId = '#order-csv-setting-modal';
        let formId = '#order-csv-setting-modal-form';
        let action = $(formId).attr('action');
        let datas = new FormData($(formId).get(0));
        // 送信前にエラーをリセット
        _error.reset()
        // 送信
        _ajax.file(action, datas, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function (xhr, status, errorThrown) {
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });
}


/**
 * 設定画面表示時のイベント設定
 */
window.order_setting_event_set = function () {

    // 営業所の選択時、受注番号自動採番
    $(document).on('change', '#order-setting-modal-section_id', function() {
        let orderNumber = $('#order-setting-modal-order_number');
        let action = orderNumber.data('generate_code');
        // 営業所クリア時は元の状態に戻す
        orderNumber.val(orderNumber.data('old_number'));
        // 受注番号がセットされている時は、自動採番セットしない
        if (orderNumber.val() === '' && $(this).val() !== '' && $(this).val() !== null) {
            _ajax.post(action, [
                {'name': 'section_id', 'value': $(this).val()},
            ], function (results) {
                $('#order-setting-modal-order_number').val(results.generated);
            }, function(xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            });
        }
    });

    // グループ、会社、営業所時、荷主・乗務員・車両の詳細ブロッククリア
    $(document).on('select2:select change', 'select.holding-select, select.company-select, select.section-select', function (e) {
        if ($(this).val() === null || $(this).val() === '') {
            let consignorDetail = $('#consignor-block');
            consignorDetail.find('dd').empty();
            consignorDetail.addClass('d-none').removeClass('d-block');
            let vehicleDetail = $('#vehicle-expect-block');
            vehicleDetail.find('table tbody').empty();
            vehicleDetail.addClass('d-none').removeClass('d-block');
            // 乗務員は営業所時、クリアしない
            if ($(this).attr('name') !== 'section_id'){
                let userDetail = $('#user-expect-block');
                userDetail.find('table tbody').empty();
                userDetail.addClass('d-none').removeClass('d-block');
            }
        }
    });

    // 荷主選択時、設定画面に詳細表示
    $(document).on('change', 'select[name="consignor_id"]', function (e) {
        let consignorDetail = $('#consignor-block');
        // clear
        consignorDetail.find('dd').empty();
        consignorDetail.addClass('d-none').removeClass('d-block');
        // set
        if ($(this).val() !== null && $(this).val() !== '') {
            _ajax.post($(this).data('selected-action'), {'id': $(this).val()}, function(results) {
                consignorDetail.find('#consignor_name').html(results.consignor.name).attr('title', results.consignor.name);
                consignorDetail.find('#consignor_address')
                    .html(results.consignor.zipcode + "<br />" + results.consignor.prefecture + results.consignor.municipality + results.consignor.address)
                    .attr('title', results.consignor.prefecture + results.consignor.municipality + results.consignor.address);
                consignorDetail.find('#consignor_phone_number').html(results.consignor.phone_number).attr('title', results.consignor.phone_number);
                consignorDetail.find('#consignor_manager_name').html(results.consignor.manager_name).attr('title', results.consignor.manager_name);
                consignorDetail.find('#consignor_manager_email').html(results.consignor.manager_email).attr('title', results.consignor.manager_email);
                consignorDetail.find('#consignor_manager_phone_number').html(results.consignor.manager_phone_number).attr('title', results.consignor.manager_phone_number);

                consignorDetail.addClass('d-block').removeClass('d-none');
            }, function(xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            }, 'json');
        }
    });

    // 乗務員選択時、設定画面に診断予定
    $(document).on('change', 'select[name="user_id"]', function (e) {
        let userDetail = $('#user-expect-block');
        let dataTable = userDetail.find('table tbody');
        // clear
        dataTable.empty();
        userDetail.addClass('d-none').removeClass('d-block');
        // set
        if ($(this).val() !== null && $(this).val() !== '') {
            _ajax.post($(this).data('selected-action'), {'id': $(this).val()}, function(results) {
                if (results.expects.length > 0) {
                    $.each(results.expects, function(index, expect) {
                        dataTable.append('<tr><td>' + expect.expect_date + '</td><td>' + expect.diagnosis_type + '</td></tr>');
                    });
                    userDetail.addClass('d-block').removeClass('d-none');
                }
            }, function(xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            }, 'json');
        }
    });

    // 車両選択時、設定画面にメンテ予定
    $(document).on('change', 'select[name="vehicle_id"]', function (e) {
        let vehicleDetail = $('#vehicle-expect-block');
        let dataTable = vehicleDetail.find('table tbody');
        // clear
        dataTable.empty();
        vehicleDetail.addClass('d-none').removeClass('d-block');
        // set
        if ($(this).val() !== null && $(this).val() !== '') {
            _ajax.post($(this).data('selected-action'), {'id': $(this).val()}, function(results) {
                if (results.expects.length > 0) {
                    $.each(results.expects, function(index, expect) {
                        dataTable.append('<tr><td>' + expect.expect_date + '</td><td>' + expect.inspection_type + '</td></tr>');
                    });
                    vehicleDetail.addClass('d-block').removeClass('d-none');
                }
            }, function(xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            }, 'json');
        }
    });
}
